import { Modal, useModal } from '@appsumo/dorado-react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faLinkedin,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpFromBracket,
  faCopy,
  faEnvelope,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';

import { SUMOTEST_SHARE_METRIC } from '~/constants/experiments';
import { SOCIAL_SHARE_COPY_NAME, ShareType } from '~/constants/global';
import { useSocialShare } from '~/hooks/share';
import { useConvertMetrics } from '~/lib/experiment';
import { Deal } from '~/types/deal';
import { COLORFUL_SOCIAL_BUTTONS, SocialShareActions } from './SocialShare';

type SocialIconType = {
  name: string;
  icon: IconProp;
  color?: string;
};

export type UTMParamsConfig = {
  email: string;
  facebook: string;
  linkedin: string;
  twitter: string;
  text: string;
};

export const getSocialButtonConfig = (useCopyIcon: boolean) => {
  return [
    {
      name: 'email',
      icon: faEnvelope as IconProp,
      color: '#2F4B4C',
    },
    {
      name: 'facebook',
      icon: faFacebook as IconProp,
      color: '#4267B2',
    },
    {
      name: 'linkedin',
      icon: faLinkedin as IconProp,
      color: '#0072B1',
    },
    {
      name: 'twitter',
      icon: faXTwitter as IconProp,
      color: '#000000',
    },
    ...(useCopyIcon
      ? [
          {
            name: SOCIAL_SHARE_COPY_NAME,
            icon: faLink as IconProp,
            color: '#2F4B4C',
          },
        ]
      : []),
  ];
};

// Component Functions

function SocialButton({
  index,
  icon,
  openSocialWindow,
  copyToClipboard,
  convert,
}: {
  index: number;
  icon: SocialIconType;
  openSocialWindow: (socialName: string) => void;
  copyToClipboard: () => void;
  convert: (metrics: Record<string, number>) => void;
}) {
  return (
    <button
      key={icon.name}
      className={`${
        index === 0 ? '' : 'ml-3'
      } rounded-full bg-gray-200 p-3 leading-3`}
      onClick={() => {
        convert({ [SUMOTEST_SHARE_METRIC]: 1 });
        icon.name === SOCIAL_SHARE_COPY_NAME
          ? copyToClipboard()
          : openSocialWindow(icon.name);
      }}
    >
      <FontAwesomeIcon
        className="h-7 w-7"
        icon={icon.icon}
        width="28"
        height="28"
        color={icon.color}
      />
    </button>
  );
}

export function ModalContent({
  shareableLinkWithParams,
  labelText,
  copyToClipboard,
  socialShare,
  convert,
  useCopyIcon = false,
}: Readonly<{
  shareableLinkWithParams: string;
  labelText: string;
  copyToClipboard: (socialName: string) => void;
  socialShare: (socialName: string) => void;
  convert: (metrics: Record<string, number>) => void;
  useCopyIcon?: boolean;
}>) {
  const socialButtonConfig = getSocialButtonConfig(useCopyIcon);

  return (
    <div className="w-full">
      {socialButtonConfig.map((icon, index) => (
        <SocialButton
          key={icon.name}
          index={index}
          icon={icon}
          openSocialWindow={() => socialShare(icon.name)}
          copyToClipboard={() => socialShare(icon.name)}
          convert={convert}
        />
      ))}
      {!useCopyIcon && (
        <div className="relative mt-4 flex w-full grow flex-col">
          <label
            htmlFor="shareable-link"
            className="mb-2 font-header font-semibold text-midnight"
          >
            {labelText}
          </label>
          <input
            type="text"
            name="shareable-link"
            className="rounded-[4px] border border-gravel bg-iceberg py-2 px-4 pr-8 text-midnight outline-none"
            readOnly
            value={shareableLinkWithParams}
          />
          <button
            className="absolute bottom-0 right-0 m-1 w-fit"
            onClick={() => {
              convert({ [SUMOTEST_SHARE_METRIC]: 1 });
              copyToClipboard(SOCIAL_SHARE_COPY_NAME);
            }}
          >
            <FontAwesomeIcon
              icon={faCopy}
              width="24"
              height="24"
              className="m-1"
            />
          </button>
        </div>
      )}
    </div>
  );
}

export default function ShareModal({
  deal,
  buttonText = 'Share',
  showButtonText = true,
  showColorfulButtons = false,
  title = '',
  labelText = 'Or copy link',
  className = '',
  shareType = ShareType.PDP,
}: Readonly<{
  deal: Deal;
  buttonText?: string;
  showButtonText?: boolean;
  showColorfulButtons?: boolean;
  title?: string;
  labelText?: string;
  className?: string;
  shareType?: ShareType;
}>) {
  const { copyShareLink, openSocialWindow, clipboardCopy, trackOnGA } =
    useSocialShare({
      dealId: deal.id,
      dealSlug: deal.slug,
      publicName: deal.public_name,
      shareType: shareType,
    });
  const { convert } = useConvertMetrics();

  const modalTitle = title || deal.public_name;

  const onSocialShare = useCallback(
    (socialName: string) => {
      if (socialName === SOCIAL_SHARE_COPY_NAME) {
        clipboardCopy();
      } else {
        openSocialWindow(socialName);
      }

      trackOnGA({ socialName });
    },
    [clipboardCopy, openSocialWindow, trackOnGA],
  );

  const [showModal, hideModal] = useModal(
    () => (
      <Modal
        hideModal={hideModal}
        title={modalTitle}
        className="absolute top-32 w-[500px] max-w-[90%] md:pb-8"
      >
        {showColorfulButtons ? (
          <SocialShareActions
            dealId={deal.id}
            dealSlug={deal.slug}
            publicName={deal.public_name}
            shareType={shareType}
            socialButtons={COLORFUL_SOCIAL_BUTTONS}
            showColorfulButtons={showColorfulButtons}
            shareLink={copyShareLink}
            showCopyText
          />
        ) : (
          <ModalContent
            shareableLinkWithParams={copyShareLink}
            labelText={labelText}
            copyToClipboard={onSocialShare}
            socialShare={onSocialShare}
            convert={convert}
          />
        )}
      </Modal>
    ),
    [modalTitle, copyShareLink, labelText, showColorfulButtons],
  );

  return (
    <div className={`flex items-center justify-end ${className}`}>
      <button className="flex items-center" onClick={() => showModal()}>
        <FontAwesomeIcon
          icon={faArrowUpFromBracket}
          width="16"
          height="16"
          className={showButtonText ? 'mr-2' : ''}
        />
        {showButtonText && buttonText}
      </button>
    </div>
  );
}
